import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns';
import PortfolioPageSlider from './PortfolioPageSlider';
import HomeSlider from './HomeSlider';

const Home =()=>{
    const [topData, setTopData] = useState({})
    const [wcdData, setWcDdata] = useState('')
    const [genData, setGenDdata] = useState('')
    const [intdData, setIntDdata] = useState('')
    const [entData, setEntDdata] = useState('')
    const [sportsData, setSportsDdata] = useState('')
    const [politicsData, setPoliticsDdata]=useState('')
    const topSData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/elastic/imagenews/list/?top_news=true`
        )
        .then((res) => {
        const topStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.map((item) => {

            return {
                content: item.images && item.images.length > 0 && item.images[0].caption? item.images[0].caption : '',
                id: item.id,
                //image: item.image, 
                image: item.images && item.images.length > 0 && item.images[0].image? item.images[0].image.replace("/thumbnails_watermark/", "/thumbnails/")  : '',                title: item.title,
                tags:item.tags,
				dateTime: item.created_at ? formatDate(item.created_at): '',
                slug:'/event/'+ item.slug,
                image_caption: item.image_caption,
            }});
        setTopData(topStories);
        });
    }
    const cateData =(type)=>{
        axios
        .get(
        `https://cms.iansnews.in/api/elastic/imagenews/list/?tags=${type}`
        ).then((res) => {
        
            if(res.data.results &&
            res.data.results.length > 0){
                const wwwData =
                res.data.results &&
                res.data.results.length > 0 &&
                res.data.results.map((item) => {
                return {
                    byline: item.byline,
                    content: item.images && item.images.length > 0 && item.images[0].caption? item.images[0].caption : '',
                    id: item.id,
                    //image: item.image,
                    image: item.images && item.images.length > 0 && item.images[0].image? item.images[0].image : '',
                    title: item.title,
                    image_count:item.image_count,
                    images:item.images,
                    tags:item.tags && item.tags.length > 0 ? item.tags:'',
                    dateTime: item.created_at ? formatDate(item.created_at): '',
                    slug:'/event/'+item.slug,
                    // slugType: tgSt && tgSt.length > 0 ? true : false,
                    video_preview:item.video_url? item.video_url :'',
                    image_caption: item.image_caption
                }})
                if(type=='kumbh2025'){
                    setWcDdata(wwwData)
                }else if(type=='national'){
                    setGenDdata(wwwData)
                }else if(type=='international'){
                    setIntDdata(wwwData)                
                }else if(type=='entertainment'){
                    setEntDdata(wwwData)      
                }else if(type=='sports'){
                    setSportsDdata(wwwData)     
                }else if(type=='politics'){
                    setPoliticsDdata(wwwData)                                 
                }
            } 
        });
    }    
	const formatDate = (timestamp)=>{
		const date = parseISO(timestamp);
		return format(date, 'MMMM dd, yyyy h:mm a');
	 }

    useEffect(() => {
        topSData()
        cateData('kumbh2025')
        cateData('national')
        cateData('international')
        cateData('entertainment')
        cateData('sports')
        cateData('politics')

      }, [])
       
    return(
        <>
        <Helmet>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta http-equiv='X-UA-Compatible' content='IE=edge'/>
            <meta name='author' content=''/>
            <link rel="icon" href="icon-new.ico" type="image/x-icon" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" /><meta name="theme-color" content=''/>
            <link rel="canonical" href="https://www.ians.in"/>
            <title>Indo-Asian News Service</title>
            <link rel="shortcut icon" href="icon-new.ico" type="image/x-icon" />
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <meta name="msvalidate.01" content="" />
            
            <link  rel="alternate" type="application/rss+xml" title="ians in" href="https://www.ians.in/rss-feed.xml"/>
            <meta name="baidu-site-verification" content="1VrDq6uj7E" />
            <meta name="google-site-verification" content="" />
            <meta name='dmca-site-verification' content='' />
            <meta property="og:title" content="Indo-Asian News Service" />
            <meta property="og:description" content="Indo-Asian News Service" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://ians.in/" />
            <meta property="og:image" content="https://ians.in/assets/img/new-logo.png" />
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@IANS"/>
            <meta name="twitter:creator" content="@IANS"/>
            <meta name="twitter:title" content="Indo-Asian News Service"/>
            <meta name="twitter:description" content="Indo-Asian News Service"/>
            <meta name="twitter:image" content="https://ians.in/assets/img/new-logo.png"/>            
        </Helmet>
        <Header />
        <HomeSlider data={topData}/>
        <PortfolioPageSlider name={"Maha Kumbh 2025"} data={wcdData}/>
        <PortfolioPageSlider name={"Politics"} data={politicsData}/>    
        <PortfolioPageSlider name={"General"} data={genData}/>       
        <PortfolioPageSlider name={"International"} data={intdData}/>       
        <PortfolioPageSlider name={"Entertainment"} data={entData}/>       
        <PortfolioPageSlider name={"Sports"} data={sportsData}/>       
        <Footer />        
        </>
    )
}

export default Home