import React, { useState, useEffect, useRef } from 'react'
import {NavLink} from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {useParams} from 'react-router-dom';

const Header =(props)=>{
    const {categoryId} = useParams()
    const inputRef = useRef(null)
    const[csDefine, setCsDefine] = useState('block')
    const [loginStatus, setLoginStatus] = useState(false)
    const [seachBox, setSearchBox] = useState(false)
    const navLinks = [
        { label: 'National', to: '/category/national/1' },
        { label: 'International', to: '/category/international/1' },
        { label: 'Business', to: '/category/business/1' },
        { label: 'Entertainment', to: '/category/entertainment/1' },
        { label: 'Science/Tech', to: '/category/science-tech/1' },
        { label: 'Sports', to: '/category/sports/1' },
        { label: 'Diaspora', to: '/category/diaspora/1' },
        { label: 'Opinion/Specials', to: '/category/opinion-specials/1' },
        { label: 'Health/Medicine', to: '/category/health-medicine/1' },
        { label: 'IANS Life', to: '/category/ians-life/1' },
        { label: 'Infographics', to: '/infographics' },
        { label: 'All Stories', to: '/all-news/1' },
      ];
    
      const moreLinks = [
        { label: 'Spotlight', to: '/category/spotlight/1' },
        { label: 'Environment', to: '/category/environment/1' },
        { label: 'Opinion/Commentary', to: '/category/opinion-commentary/1' },
        { label: 'WildLife', to: '/category/wildLife/1' },
      ];
    
      const navItems = navLinks.map((link, index) => (
        <li key={index}>
          <NavLink className={`nav-link scrollto ${index === 0 ? 'active' : ''}`} to={link.to}>
            {link.label}
          </NavLink>
        </li>
      ));
    
      const moreNavItems = moreLinks.map((link, index) => (
        <li key={index}>
          <NavLink to={link.to}>{link.label}</NavLink>
        </li>
      ));
    const history = useHistory();
    const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update
    const year = new Date().getFullYear();
    const locale = 'en';
    const date = `${today.toLocaleDateString(locale, { month: 'long' })} ${today.getDate()}, ${year} \n\n`;
    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
		const [search, setSearch] = useState();
    const [isVisible, setIsVisible] = useState(false);

	const logoutUser=()=>{
		localStorage.clear()
		history.push('/');
	}
	const [searchHead, SetSHead] = useState('template-search');
    const inputSData=(event)=>{
        localStorage.removeItem(search);
        setSearch(event.target.value);
      }
	const onSubmit = (e) => {
        e.preventDefault()
        localStorage.removeItem(search);

        localStorage.setItem('search', search ? search :'');
          let path = '/search/1';
            SetSHead('template-search')
			//window.reload()
			window.location.pathname=path;
        history.push(path);

        // console.log(conFom)
      }
      const seachBoxpop=()=>{
        if(window && window.location && window.location.pathname){
          let patgTemp =window.location.pathname;
          patgTemp = patgTemp.substring(0,12);
          if (patgTemp == '/all-photos/'){
          setSearchBox(false)
        }else{
          setSearch('')
          setSearchBox(!seachBox)
        }
      }
      }
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      };
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };      
      useEffect(() => {
        scrollToTop()
        if(localStorage && localStorage.accessToken){
          setLoginStatus(true)
      }
      if(window && window.location && window.location.pathname){
        let patggTemp =window.location.pathname;
        patggTemp = patggTemp.substring(0,12);
        if (patggTemp == '/all-photos/'){
          setCsDefine('none')
        }else{
          setCsDefine('block')
      }
    }
      if (seachBox) {
        inputRef.current.focus()
      }
        window.addEventListener("scroll", toggleVisibility)
        return () => window.removeEventListener("scroll", toggleVisibility)
      }, [categoryId, seachBox])  
      
    return (
        <>
     <div id="topbar" className="d-flex align-items-center">
		 <div className="container d-flex justify-content-between">
			 <div className="contact-info d-flex align-items-center">
				 <a href=""> Updated on  {date} {time}</a>

            {localStorage && localStorage.userName &&
				 <a href=""><i className="fa fa-user"></i>Welcome <span className="user-sec">{localStorage.userName}</span></a>
            }
			 </div>
			 <div className="d-none d-lg-flex social-links align-items-center">
				 
         <a title="IANS News X" target="_blank" href="https://twitter.com/ians_india">
            <svg fill="#8b8787" xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512">
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
            </svg>
          </a>

				 <a title="IANS News Facebook" target="_blank" href="https://www.facebook.com/iansnews.in/"><i className="fab fa-facebook" aria-hidden="true"></i></a>

				 <a title="IANS News Instagram" target="_blank" href="https://www.instagram.com/iansnews.in/"><i className="fab fa-instagram" aria-hidden="true"></i></a>

				 <a title="IANS India YouTube" target="_blank" href="https://www.youtube.com/user/iansindia"><i className="fab fa-youtube" aria-hidden="true"></i></a>

         <a title="IANS News Threads" target="_blank" href="https://www.threads.net/@iansnews.in" >
          <svg fill="#8b8787" xmlns="http://www.w3.org/2000/svg" height="15" width="14" viewBox="0 0 448 512">
            <path d="M331.5 235.7c2.2 .9 4.2 1.9 6.3 2.8c29.2 14.1 50.6 35.2 61.8 61.4c15.7 36.5 17.2 95.8-30.3 143.2c-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2c-32.3-41-48.9-98.1-49.5-169.6V256v-.2C17 184.3 33.6 127.2 65.9 86.2C102.2 40.1 156.2 16.5 226.4 16h.3c70.3 .5 124.9 24 162.3 69.9c18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4c-29.2-35.8-73-54.2-130.5-54.6c-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3c28 35.6 71.2 53.9 128.2 54.4c51.4-.4 85.4-12.6 113.7-40.9c32.3-32.2 31.7-71.8 21.4-95.9c-6.1-14.2-17.1-26-31.9-34.9c-3.7 26.9-11.8 48.3-24.7 64.8c-17.1 21.8-41.4 33.6-72.7 35.3c-23.6 1.3-46.3-4.4-63.9-16c-20.8-13.8-33-34.8-34.3-59.3c-2.5-48.3 35.7-83 95.2-86.4c21.1-1.2 40.9-.3 59.2 2.8c-2.4-14.8-7.3-26.6-14.6-35.2c-10-11.7-25.6-17.7-46.2-17.8H227c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6 .4 99.9 39.5 103.7 107.7l-.2 .2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3c25.6-1.4 54.6-11.4 59.5-73.2c-13.2-2.9-27.8-4.4-43.4-4.4c-4.8 0-9.6 .1-14.4 .4c-42.9 2.4-57.2 23.2-56.2 41.8l-.1 .1z"/>
          </svg>
          </a>

			 </div>
		 </div>
	 </div>

    <header id="gen-header" className="gen-header-style-1 gen-has-sticky">
        <div className="gen-bottom-header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <NavLink className="navbar-brand" to="/">
                                <img className="img-fluid logo" src="/images/Logo.png" alt="streamlab-image"/>
                            </NavLink>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <div id="gen-menu-contain" className="gen-menu-contain">
                                    <ul id="gen-main-menu" className="navbar-nav ml-auto">
                                        <li className="menu-item"><NavLink to="/category/national/1">General</NavLink></li>
                                        <li className="menu-item"><NavLink to="/category/international/1">International</NavLink></li>
                                        <li className="menu-item"><NavLink to="/category/politics/1">Politics</NavLink></li>
                                        <li className="menu-item"><NavLink to="/category/sports/1">Sports</NavLink></li>
                                        <li className="menu-item"><NavLink to="/category/entertainment/1">Entertainment</NavLink></li>
                                        <li className="menu-item"><NavLink to="/all-events/1">All Events</NavLink></li>
                                        <li className="menu-item">
                                          {loginStatus && <NavLink to="/all-photos/1">All Photos</NavLink>}
                                          {!loginStatus && <NavLink to="/login">All Photos</NavLink>}
                                          </li>
                                        <li className="menu-item"><a >More</a>
                                            {/* <i className="fa fa-chevron-down gen-submenu-icon"></i> */}
                                            <ul className="sub-menu">
                                                <li className="menu-item"><NavLink to="/category/kumbh2025/1">Maha Kumbh&nbsp;2025</NavLink></li>
                                                <li className="menu-item"><NavLink to="/category/business/1">Business</NavLink></li>
                                                <li className="menu-item"><NavLink to="/category/cinema/1">Cinema</NavLink></li>
                                                <li className="menu-item"><NavLink to="/category/fashion/1">Fashion</NavLink></li>
                                                {/* <li className="menu-item"><NavLink to="/category/cricket/1">Cricket</NavLink></li> */}
                                                <li className="menu-item"><NavLink to="/category/features/1">Features</NavLink></li>
                                                <li className="menu-item"><NavLink to="/category/wildLife/1">WildLife</NavLink></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                             </div>
                            <div className="gen-header-info-box">
                                <div className="gen-menu-search-block">
                                    <a  onClick={seachBoxpop} style={{display:csDefine}}><i className="fa fa-search"></i></a>
                                {seachBox &&                                  
                                  <div className="gen-search-form">
                                        <form className="search-form" onSubmit={onSubmit}>
                                            <label>
                                                <span className="screen-reader-text"></span>
                                                <input ref={inputRef} type="text" className="form-control" placeholder="Search …" onChange={inputSData} name="search" value={search}/>
                                            </label>
                                            <button type="submit" className="search-submit"><span className="screen-reader-text"></span></button>
                                        </form>
                                    </div>
                                    }
                                </div>
                                <div className="login-d">
                                  {!loginStatus &&        
                                  <NavLink to="/login" className="login-btn scrollto">LOGIN</NavLink>
                                    }
                                    {loginStatus &&        
                                        <a href="" title="Login" className="login-btn scrollto" onClick={logoutUser}>
                                            Logout
                                        </a>
                                      } 
								                </div>
                            </div>
                            
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fas fa-bars"></i>
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
    
        </>
    )
}
export default Header;